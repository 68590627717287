<template>
  <div class="ra-basic ra-basic-text">
    <h2>When "default" in Vue property - can be inadequacy</h2>
    <p>
      and why it is wrong in documentation?
    </p>
    <br />
    <br />
    <p>
      Here I want to describe some moments
      with <b>default</b> property in <b>Vue</b> for begginers
      <br />
      <br />
      lets look at some examples - code from <b>junior</b> developer,
      with simple three properties and simple types of them:
      <RaCode>
        {{ code.first }}
      </RaCode>
      looks classic for good reviewer,
      and here is only one <b>valuable</b> mistake:
      <RaCode>
        {{ code.second }}
      </RaCode>
      Why? Because <b>Vue</b>
      automatically set every
      <b>Boolean</b> property to <b>default: false</b>
      <br />
      So - <b>Boolean</b> property never needs a <b>default</b> -
      <b>IF</b> you want to <b>default: false</b>,
      <br />
      otherway - you can use <b>default with Boolean</b>
      <br />
      Remember that :)
      <br />
      <br />
      Next one - <b>String</b> and <b>Number</b> property looks good
      for first look
      <br />
      But! <b>(Spoiler alert)</b>
      it's not good in real life and below I will describe why
      <br />
      <br />
      Lets look at next example with other types:
      <RaCode>
        {{ code.third }}
      </RaCode>
      What is wrong?
      for example:
      <RaCode lang="html">
        {{ code.fourth }}
      </RaCode>
      we set <b>objectProperty</b> only for 4 and 5 elements
      to see what will happen here,
      we expect first, second and third elements to be - name Alan
      (<b>default</b>),
      and fourth and fifth - name Bob
      <br />
      <br />
      Here we go:
      <RaCode lang="html">
        {{ code.fifth }}
      </RaCode>
      Why it's happen?
      <br />
      It's wrong, because every exemple of your component,
      will use only default value
      <br />
      <b>Vue</b> must use factory function for instance <b>default</b> props
      for next types:
      <br />
      <ul>
        <li>Object</li>
        <li>Array</li>
        <li>Date</li>
        <li>Function</li>
        <li>Symbol</li>
      </ul>
      For solution we <b>need</b> to use this syntax:
      <RaCode>
        {{ code.sixth }}
      </RaCode>
      And now we have what we want:
      <RaCode lang="html">
        {{ code.seventh }}
      </RaCode>
      This bug detailed described in
      <RaLink
        link="https://github.com/vuejs/vue/issues/1032"
        styled
      >
        ISSUE #1032
      </RaLink>
      <RaQuote>
        Example of my code can not working in 3+ version Vue,
        <br />
        This example is to demonstrate playback
      </RaQuote>
      And core thing what I want to describe here:
      <br />
      <b>IMHO</b>
      <br />
      In my opinion we <b>always</b> need to use this syntax,
      doesen't matter <b>String</b> or <b>Object</b> you have in
      <b>default</b> property,
      it's about the habit of using <b>Vue</b> correctly everywhere
      for any things:
      <RaCode>
        {{ code.eight }}
      </RaCode>
      May you remember <b>data</b> styled like this too, for same reason:
      <RaCode>
        {{ code.ten }}
      </RaCode>
      Same things are actual to TypeScript decorators -
      <RaLink
        link="https://github.com/kaorun343/vue-property-decorator"
        styled
      >
        vue-property-decorator
      </RaLink>
      <RaCode>
        {{ code.ninth }}
      </RaCode>
      <br />
      <br />
      Thanks for the reading
      <br />
      stay tuned
      <br />
      Bye bye
    </p>
    <br />
    <p>
      {{ authors.length > 1 ? 'Authors:' : 'Author:' }}
      <RaLink
        v-for="(author, key) in authors"
        :key="key"
        :link="author.link"
        follow
        styled
      >
        {{ author.nick }}
      </RaLink>
    </p>
    <div
      v-if="vote && vote.up >= 0 && vote.down >= 0"
      class="ra-vote-block"
    >
      <RaButton
        v-title="`${vote.up} votes`"
        vote
        vote-type="up"
        type="success"
        class="ra-vote-button"
        @click="makeVote(postId, true)"
      />
      <RaButton
        v-title="`${vote.down} votes`"
        vote
        vote-type="down"
        type="danger"
        class="ra-vote-button"
        @click="makeVote(postId, false)"
      />
    </div>
  </div>
</template>

<script>
import VTitle from 'v-title'

import RaCode from '@/components/elements/RaCode.vue'
import RaLink from '@/components/elements/RaLink.vue'
import RaQuote from '@/components/elements/RaQuote.vue'
import RaButton from '@/components/elements/RaButton.vue'

import fingerVote from '@/mixins/fingerVote'

import codeData from '@/components/articles/DefaultProperty/code'

export default {
  name: 'DefaultProperty',

  components: {
    RaCode,
    RaLink,
    RaQuote,
    RaButton
  },

  directives: {
    title: VTitle
  },

  mixins: [fingerVote],

  metaInfo: {
    title: 'When "default" in Vue property - can be inadequacy',
    titleTemplate: '%s | Roman Almazov',

    meta: [
      {
        name: 'description',
        content: 'and why it is wrong in documentation?'
      },
      {
        name: 'og:title',
        content: 'When "default" in Vue property - can be inadequacy'
      },
      {
        name: 'twitter:title',
        content: 'When "default" in Vue property - can be inadequacy'
      },
      {
        name: 'og:description',
        content: 'and why it is wrong in documentation?'
      },
      {
        name: 'twitter:description',
        content: 'and why it is wrong in documentation?'
      },
      { name: 'og:url', content: 'https://ralmaz.pro/blog/default-property' },
      {
        name: 'twitter:url',
        content: 'https://ralmaz.pro/blog/default-property'
      },

      { name: 'og:type', content: 'article' },
      { name: 'article:published_time', content: '2020-05-05' },
      { name: 'article:modified_time', content: '2020-05-07' },
      { name: 'article:author', content: 'RALMAZ' },
      { name: 'article:section', content: 'Vue.js' },
      { name: 'article:tag', content: 'Vue.js' },
      { name: 'article:tag', content: 'JavaScript' }
    ]
  },

  data: () => ({
    postId: 1,
    createdAt: '2020-05-05',
    authors: [
      {
        nick: '@RALMAZ',
        link: 'https://ralmaz.pro'
      }
    ]
  }),

  computed: {
    code() {
      return codeData
    }
  },

  mounted() {
    this.initFingerVote(this.postId)
  }
}
</script>
