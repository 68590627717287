export default {
  first: `
    props: {
      booleanProperty: {
        type: Boolean,
        default: false
      },

      stringProperty: {
        type: String,
        default: 'My property value'
      },

      numberProperty: {
        type: Number,
        default: 6
      }
    }
  `,

  second: `
    // WRONG
    booleanProperty: {
      type: Boolean,
      default: false
    }

    // TRUE
    booleanProperty: {
      type: Boolean
    }
  `,

  third: `
    props: {
      objectProperty: {
        type: Object,
        default: {
          name: 'Alan',
          age: 27
        }
      },

      arrayProperty: {
        type: Array,
        default: [
          1,
          2,
          3,
          4
        ]
      }
    }
  `,

  fourth: `
    <TestComponent />
    <TestComponent />
    <TestComponent />
    <TestComponent :objectProperty="{name: 'Bob', age: 33}" />
    <TestComponent :objectProperty="{name: 'Bob', age: 33}" />

    <!-- Component Inside: -->
    <div>
     {{ objectProperty.name }}
    </div>
  `,

  fifth: `
    <div> Alan </div>
    <div> Alan </div>
    <div> Alan </div>
    <div> Alan </div>
    <div> Alan </div>
  `,

  sixth: `
    props: {
      objectProperty: {
        type: Object,
        default: () => ({
          name: 'Alan',
          age: 27
        })
      }
    }
  `,

  seventh: `
    <div> Alan </div>
    <div> Alan </div>
    <div> Alan </div>
    <div> Bob  </div>
    <div> Bob  </div>
  `,

  eight: `
    props: {
      booleanProperty: {
        type: Boolean,
        default: () => true
      },

      stringProperty: {
        type: String,
        default: () => 'My property value'
      },

      numberProperty: {
        type: Number,
        default: () => 6
      }
    }
  `,

  ninth: `
    @Prop({ default: () => ({
        name: 'Bob',
        age: 33
      })
    }) readonly prop!: SomeType
  `,

  ten: `
    data: () => ({
      some: 1
    })
  `
}
